import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ONE_PALETTE from './images/1palette.png'
import HR from './images/hr.png'
import DOM from './images/dom.png'
import CENT from './images/cent.png'
import LONGDO_SDK from './images/longdo_sdk.png'
import LONGDO_PANO from './images/longdo_pano.png'
import DOL from './images/dol.png'
import RM from './images/rm.png'
import LONGDO_ANDROID from './images/longdo_android.png'
import LONGDO_TRAFFIC from './images/longdo_traffic.png'
import PROMPTPAI from './images/promptpai.png'
import ITIM from './images/itim.png'
import ONEPAGE from './images/onepage.png'
import CITIZEN from './images/citizen.png'
import TAGGERBOT from './images/taggerbot.png'
import MJPEG_LIB from './images/android_mjpeg.jpg'
import GISTDA_GO from './images/gistda_go.png'
import CROSS_SECTION from './images/cross_section.png'
import CAMERA_SERVER from './images/camera_server.jpg'
import RAPID_MINER from './images/rapid_miner.png'
import MIMO from './images/mimo.png'
import POMELO_ANDROID from './images/pomelo_android.png'
import POMELO_IOS from './images/pomelo_ios.png'
import OOCA_IOS from './images/ooca_ios.png'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarIcon from '@material-ui/icons/Star';
import LayersIcon from '@material-ui/icons/Layers';
import BuildIcon from '@material-ui/icons/Build';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import InfoIcon from '@material-ui/icons/Info';
import BusinessIcon from '@material-ui/icons/Business';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import DescriptionIcon from '@material-ui/icons/Description';
import GitHubIcon from '@material-ui/icons/GitHub';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    link: {
        textDecoration: 'none',
    },
    tags: {
        marginTop: theme.spacing(2),
        color: "#868686",
        fontSize: '1em',
    },
    nested: {
        paddingLeft: theme.spacing(8),
        paddingBottom: theme.spacing(4),
    },
    careerTitle: {
        paddingLeft: theme.spacing(9),
        // fontWeight: 'bold',
    },
    skills: {
        // display: 'none',
    },
    chip: {
        margin: theme.spacing(1, 1, 0, 0),
    },
    careers: {
        marginTop: theme.spacing(4),
    },
    logo: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        padding: theme.spacing(0, 0, 6),
    }
}));

const cards = [
    {
        title: "Ooca applications, APIs and infrastructure",
        desc: "The most impactful mental wellness platform in ASEAN",
        detail: "We provider online counselling and many other services to help improve people mental wellbeing.",
        imageUrl: OOCA_IOS,
        url: "https://apps.apple.com/th/app/ooca-%E0%B8%9B%E0%B8%A3-%E0%B8%81%E0%B8%A9%E0%B8%B2%E0%B8%9B-%E0%B8%8D%E0%B8%AB%E0%B8%B2%E0%B9%83%E0%B8%88/id1260476046?l=th",
        tags: [
            "Flutter",
        ],
    },
    {
        title: "Pomelo applications",
        desc: "Southeast Asia leading fashion-tech company's native applications (iOS and Android)",
        detail: "Provide the best shopping experience on Omni-channel for fashion industry.",
        imageUrl: POMELO_IOS,
        url: "https://apps.apple.com/th/app/pomelo-fashion/id1098083916",
        tags: [
            "Swift",
            "Kotlin",
            "Flutter",
        ],
    },
    {
        title: "Pomelo API and DevOps",
        desc: "Backend services and infrastructure to support frontend clients and internal tools",
        detail: "Fast, secure and reliable APIs. Auto scale during campaigns",
        imageUrl: POMELO_ANDROID,
        url: "https://play.google.com/store/apps/details?id=com.mobile.pomelo&hl=en_US",
        tags: [
            "Node.js",
            "Terraform",
            "ECS",
            "WAF",
            "RDS",
            "CF",
            "Redis",
        ],
    },
    {
        title: "1palette",
        desc: "Facebook ads audience targeting",
        detail: "Sync data (e.g. posts, messages, comments) from Facebook Fanpage. Automatically classify customer into segments based on chat messages and activities on Fanpage. Send them back to Facebook to be used as ads audiences for appropriate strategy and content.",
        imageUrl: ONE_PALETTE,
        url: "https://1palette.insightera.co.th/",
        tags: [
            "Python",
            "Golang",
            "Tensorflow",
            "Vue.js",
            "Mongo",
            "Facebook API",
            "RabbitMQ",
        ],
    },
    {
        title: "DOM",
        desc: "Social Listening and Analytics",
        detail: "Gather data from multiple social media--Facebook, Twitter, Youtube, Instagram, Pantip and News website. Analyze data to determine overall sentiment and incidents. Generate reports consist of summary and recommendation.",
        imageUrl: DOM,
        url: "https://www.insightera.co.th/dom/",
        tags: [
            "Golang",
            "Node.js",
            "Mongo",
            "Redis",
            "Wordpress API",
            "Facebook API",
            "RabbitMQ",
            "Elasticsearch",
            "Puppeteer",
        ],
    },
    {
        title: "CENT",
        desc: "Social medial Management Tool",
        detail: "Manage multiple social media in one place. Also include features to facilitate practical operations--ticketing, task assignment and content scheduling.",
        imageUrl: CENT,
        url: "https://cent.insightera.co.th/",
        tags: [
            "Product design",
            "SCRUM Master",
        ],
    },
    // {
    //     title: "BRIAN",
    //     desc: "Digital Marketing Competitor Analysis",
    //     detail: "Monitor competitor activities e.g. campaign, PR, traffic and ads. Summarize on easy to understand dashboards.",
    //     imageUrl: BRIAN,
    //     url: "https://www.insightera.co.th/brian/",
    //     tags: [
    //         "Product design",
    //         "Node.js",
    //         "Puppeteer",
    //         "Google ads",
    //         "Facebook ads",
    //     ],
    // },
    // {
    //     title: "ALICE",
    //     desc: "Chatbot Development Tool",
    //     detail: "Internal tool to help developing chatbot for project implementation business. GUI to help train model, analyze conversation flow.",
    //     imageUrl: ALICE,
    //     url: "https://www.insightera.co.th/alice/",
    //     tags: [
    //         "Product design",
    //         "SCRUM Master",
    //         "NLP"
    //     ],
    // },
    // {
    //     title: "Branding websites",
    //     desc: "Wordpress website for brands",
    //     detail: "Websites developed using Wordpress. Some customization to fit customer needs. Mostly used as branding websites. Cover various industries e.g. real estate, hospital, private company and retail",
    //     imageUrl: SENA,
    //     url: "https://www.sena.co.th/",
    //     tags: [
    //         "Resource Management",
    //         "Technical Advise",
    //     ],
    // },
    {
        title: "HR Analytics",
        desc: "HR software and analytics dashboard",
        detail: "Build on top of MS Office365 platform--Power App, Power Automate, PowerBI and SharePoints to provide one stop services for HR team.",
        imageUrl: HR,
        url: "https://course-gable-openedx.web.app/",
        tags: [
            "Product Owner",
            "Rapid prototype",
            "React.js",
            "Python",
            "OpenEdx",
        ],
    },
    {
        title: "Longdo Map Android",
        desc: "Android Application of Longdo Map",
        detail: "Application provide map functionality and other geographic related tasks e.g. POI search, navigation, check-in and add/edit/remove POI.",
        imageUrl: LONGDO_ANDROID,
        url: "https://play.google.com/store/apps/details?id=com.longdo.map.android",
        tags: [
            "Java",
            "Kotlin",
            "Map SDK",
            "OpenGL ES2.0",
        ],
    },
    {
        title: "Longdo Traffic Android",
        desc: "Android Application of Longdo Traffic",
        detail: "Application provide map functionality especially functions related to current traffic conditions e.g. traffic index and view live traffic cameras",
        imageUrl: LONGDO_TRAFFIC,
        url: "https://play.google.com/store/apps/details?id=com.longdotraffic.android",
        tags: [
            "Java",
            "Kotlin",
            "Map SDK",
            "OpenGL ES2.0",
        ],
    },
    {
        title: "Promptpai",
        desc: "Android Application of Promptpai",
        detail: "Use Phone No as a representation of personal address similar to how Phone No can be used to represent a personal bank account in Promptpay",
        imageUrl: PROMPTPAI,
        url: "https://play.google.com/store/apps/details?id=com.promptpai.promptpai",
        tags: [
            "Java",
            "Kotlin",
            "Map SDK",
            "Androidx",
        ],
    },
    {
        title: "Longdo Map Android SDK",
        desc: "Android SDK to integrate Longdo Map into applications",
        detail: "Android SDK which enable developers to easily integrate Longdo Map into their applications by providing SDK. The SDK consist of basic map functionality, geographic related operation--measure distance and view public traffic cameras. Also include adding 3D models to show on a Map.",
        imageUrl: LONGDO_SDK,
        url: "https://map.longdo.com/api-mobile/android/document/index.html",
        tags: [
            "Java",
            "OpenGL ES2.0",
        ],
    },
    {
        title: "Longdo Panorama SDK",
        desc: "Google Streetview liked by Longdo Map",
        detail: "Web library which enable web developer to easily integrate panorama view (Google Streetview-liked) into their websites. Provide basic panorama functionality e.g. pan, zoom, move and go to next frame. Also include basic 3D geographic operation e.g. measure distance in 3D and add 3D models. Support render point cloud on the scene",
        imageUrl: LONGDO_PANO,
        url: "https://api.longdo.com/pano/",
        tags: [
            "Vanilla JS",
            "WebGL",
        ],
    },
    {
        title: "CitizenInfo Android App",
        desc: "Android application by DGA provide useful information for citizens",
        detail: "An application provide detailed information of each government institution e.g. available services, documents required for each services and search and filter nearby offices with specific services.",
        imageUrl: CITIZEN,
        url: "https://play.google.com/store/apps/details?id=th.go.info.citizeninfo",
        tags: [
            "Java",
            "Kotlin",
            "Map SDK",
        ],
    },
    {
        title: "DRR - RM",
        desc: "Rural Road Management System",
        detail: "Rural road assets management and tracking system. Assets include poles, guardrail, traffic sign and traffic light. User can view 360 first person view along the road to examine road condition and surrounding area.",
        imageUrl: RM,
        url: "https://rm.drr.go.th",
        tags: [
            "PHP",
            "Drupal",
            "Flash",
            "Data Engineer",
            "WebALP - Iwane",
        ],
    },
    {
        title: "DOL - IPL",
        desc: "Landuse Management System",
        detail: "Landuse management and tracking system. Core features include edit polygon, search for particular area, view 360 scene of streets nearby.",
        imageUrl: DOL,
        url: "http://dolview.dol.go.th/",
        tags: [
            "PHP",
            "Symfony",
            "Laravel",
            "Data Engineer",
        ],
    },
    {
        title: "DOH - ITIM",
        desc: "Highway Integrated Traffic Information Management System",
        detail: "Provide useful information about highway status and nearby POI for public use e.g. location of Gas station, accidents, traffic condition of each highway and recommended routes.",
        imageUrl: ITIM,
        url: "https://play.google.com/store/apps/details?id=com.longdo.android.itim",
        tags: [
            "Java",
            "Kotlin",
            "Map SDK",
        ],
    },
    {
        title: "DRR Onepage",
        desc: "Single page web application to show road surrounded environment",
        detail: "Single web page that shows detailed information about roads environment e.g. constructions along the roads, intersections, road surface repair history, building and residences",
        imageUrl: ONEPAGE,
        url: "http://rm.drr.go.th/onepage/",
        tags: [
            "Vanilla JS",
            "Raphaël",
        ],
    },
    {
        title: "TaggerBot",
        desc: "Document classification tool",
        detail: "NSC and Microsoft Imagine Cup Award Winning. Automatically classify document into pre-defined groups. Support add/edit/remove sample documents manually and re-use those samples as new dataset for re-training. Also support searching documents by assigned tags, location, etc",
        imageUrl: TAGGERBOT,
        url: "http://kmschool.thaissf.org/taggerbot/taggerbot-admin/web/login",
        tags: [
            "Python",
            "PHP",
            "Java",
            "NLP",
        ],
    },
    {
        title: "GISTDA GO",
        desc: "View satellite photos",
        detail: "GISTDA GO (Global Online) provides public access to satellite images from Thaichote satellite. The service is provided by Geo-Informatics and Space Technology Development Agency (Public Organization).",
        imageUrl: GISTDA_GO,
        url: "https://play.google.com/store/apps/details?id=com.longdo.gistdago.android",
        tags: [
            "Java",
            "Kotlin",
            "Map SDK",
        ],
    },
    {
        title: "Open source Android MJpeg View",
        desc: "Android customer View for playing MJpeg",
        detail: "Reading Jpeg images and display one by one using configured framerate. Also support multiple display modes.",
        imageUrl: MJPEG_LIB,
        url: "https://github.com/perthcpe23/android-mjpeg-view",
        tags: [
            "Java",
            "MJpeg",
        ],
    },
    {
        title: "Traffic camera server",
        desc: "IP camera streaming server (HLS and MJpeg)",
        detail: "Streaming server developed using pure Java. It supports multiple input sources e.g. various IP camera brands, static images, etc.",
        imageUrl: CAMERA_SERVER,
        url: "https://bma-itic1.iticfoundation.org/mjpeg2.php?camid=test",
        tags: [
            "Java",
            "PHP",
            "HLS",
            "MJpeg",
        ],
    },
    {
        title: "Road cross section view",
        desc: "Configurable road cross section on web",
        detail: "A small library display road cross section using Raphaël (SVG) library.",
        imageUrl: CROSS_SECTION,
        url: "https://youtu.be/NQkoTIpTwRA",
        tags: [
            "Javascript",
            "Raphaël",
        ],
    },
    {
        title: "MIMO Projection Control System",
        desc: "[Senior project] Multiple Input to Multiple Output Projection Control System",
        detail: "Design and control how multiple input sources from different screens sharing will be displayed on multiple monitors/projectors.",
        imageUrl: MIMO,
        url: "https://drive.google.com/drive/folders/18fCu7JI8kdOYWT9n4aKs8Y8YO0ybrMUG?usp=sharing",
        tags: [
            "Java",
            "Swing",
            "Network",
            "MJpeg",
            "Video Compression",
        ],
    },
    {
        title: "Workflow framework to support data analytics in cloud computing",
        desc: "[Internship] Co-author of a conference paper",
        detail: "Modify Rapid Miner to use Hadoop as compute engine instead of local CPU result in increasing capability to handle Big Data.",
        imageUrl: RAPID_MINER,
        url: "https://www.researchgate.net/publication/261434541_Workflow_framework_to_support_data_analytics_in_cloud_computing",
        tags: [
            "Rapid Miner",
            "Java",
            "Hadoop",
            "Mahout",
            "Oozie",
        ],
    },
];

const logos = [
    { src: "https://skillicons.dev/icons?i=go", alt: "golang" },
    { src: "https://skillicons.dev/icons?i=flutter", alt: "Flutter" },
    { src: "https://skillicons.dev/icons?i=nodejs", alt: "nodejs" },
    { src: "https://skillicons.dev/icons?i=swift", alt: "swift" },
    { src: "https://skillicons.dev/icons?i=py", alt: "python" },
    { src: "https://skillicons.dev/icons?i=kotlin", alt: "kotlin" },
    { src: "https://skillicons.dev/icons?i=java", alt: "Java" },
    { src: "https://skillicons.dev/icons?i=ts", alt: "ts" },
    { src: "https://skillicons.dev/icons?i=js", alt: "js" },
    { src: "https://skillicons.dev/icons?i=dart", alt: "Dart" },
    { src: "https://skillicons.dev/icons?i=aws", alt: "AWS" },
    { src: "https://skillicons.dev/icons?i=gcp", alt: "GCP" },
    { src: "https://skillicons.dev/icons?i=firebase", alt: "Firebase" },
    { src: "https://skillicons.dev/icons?i=nginx", alt: "nginx" },
    { src: "https://skillicons.dev/icons?i=mysql", alt: "mysql" },
    { src: "https://skillicons.dev/icons?i=postgres", alt: "PostgreSQL" },
    { src: "https://skillicons.dev/icons?i=mongodb", alt: "MongoDB" },
    { src: "https://skillicons.dev/icons?i=redis", alt: "Redis" },
    { src: "https://skillicons.dev/icons?i=prisma", alt: "prisma" },
    { src: "https://skillicons.dev/icons?i=androidstudio", alt: "Android" },
    { src: "https://skillicons.dev/icons?i=gradle", alt: "gradle" },
    { src: "https://skillicons.dev/icons?i=apple", alt: "iOS" },
    { src: "https://skillicons.dev/icons?i=tensorflow", alt: "Tensorflow" },
    { src: "https://skillicons.dev/icons?i=bash", alt: "Bash" },
    { src: "https://skillicons.dev/icons?i=docker", alt: "Docker" },
    { src: "https://skillicons.dev/icons?i=github", alt: "Github" },
    { src: "https://skillicons.dev/icons?i=gitlab", alt: "Gitlab" },
    { src: "https://skillicons.dev/icons?i=bitbucket", alt: "bitbucket" },
    { src: "https://skillicons.dev/icons?i=graphql", alt: "Graphql" },
    { src: "https://skillicons.dev/icons?i=terraform", alt: "terraform" },
    { src: "https://skillicons.dev/icons?i=elasticsearch", alt: "elasticsearch" },
    { src: "https://skillicons.dev/icons?i=react", alt: "react" },
    { src: "https://skillicons.dev/icons?i=nextjs", alt: "NextJS" },
    { src: "https://skillicons.dev/icons?i=tailwind", alt: "Tailwind" },
    { src: "https://skillicons.dev/icons?i=notion", alt: "notion" },
    { src: "https://skillicons.dev/icons?i=vim", alt: "vim" },
]

// const coreCompetencies = [
//     { name: "Delivering results" },
//     { name: "Leading changes" },
//     { name: "Working under uncertainty" },
//     { name: "Creativity and flexible" },
//     { name: "Product Development" },
//     { name: "Lean startup" },
//     { name: "Agile methodology" },
//     { name: "Team building" },
//     { name: "Communication" },
//     { name: "Coaching" },
//     { name: "Rapid software prototyping" },
// ]

const programmings = [
    { name: "Flutter" },
    { name: "Golang" },
    { name: "Swift" },
    { name: "Kotlin" },
    { name: "Java" },
    { name: "Javascript" },
    { name: "Python" },
]

const tools = [
    { name: "Terraform" },
    { name: "Vue.js" },
    { name: "React.js" },
    { name: "Next.js" },
    { name: "React" },
    { name: "Tensorflow" },
    { name: "OpenGL" },
    { name: "WebGL" },
    { name: "Hadoop" },
    { name: "RabbitMQ" },
    { name: "EFK" },
    { name: "Kafka" },
    { name: "Symfony" },
    { name: "Laravel" },
    { name: "Drupal" },
    { name: "GIN" },
    { name: "FastAPI" },
    { name: "Three.js" },
    { name: "Raphaël" },
    { name: "AWS", color: "rgb(255 149 0)" },
    { name: "GCP", color: "rgb(255 149 0)" },
    { name: "ECS", color: "rgb(255 149 0)" },
    { name: "Lambda", color: "rgb(255 149 0)" },
    { name: "Beanstalk", color: "rgb(255 149 0)" },
    { name: "RDS", color: "rgb(255 149 0)" },
    { name: "Cloudfront", color: "rgb(255 149 0)" },
    { name: "WAF", color: "rgb(255 149 0)" },
]

const databases = [
    { "name": "Mongo" },
    { "name": "PostgreSQL + PostGIS" },
    { "name": "MySQL" },
    { "name": "Elasticsearch" },
    { "name": "Redis" },
    { "name": "Memcache" },
]

const other = [
    { "name": "Scalable Design" },
    { "name": "Design Pattern" },
    { "name": "Natural Language Processing - NLP" },
    { "name": "Machine Learning - ML" },
    // { "name": "Data Engineer" },
    // { "name": "Data Science" },
    { "name": "Linux" },
    { "name": "DevOps" },
    { "name": "Network" },
    { "name": "Firebase" },
    { "name": "3D geometry" },
    { "name": "Computer security" },
]

const careers = [
    {
        code: "ooca",
        name: "Ooca, Telemedica",
        title: "Head of Product and Technology",
        date: "Oct 2023 - NOW",
        tags: [
            { name: "Head of Technology" },
            { name: "Head of Product" },
            { name: "Direction Planning" },
            { name: "Feature Roadmap" },
            { name: "Team Building" },
            { name: "Mental Health" },
            { name: "Full-stack" },
            { name: "Flutter", color: "secondary" },
            { name: "Golang", color: "secondary" },
            { name: "Python", color: "secondary" },
            { name: "Node.js", color: "secondary" },
            { name: "Next.js", color: "secondary" },
            { name: "ML", color: "secondary" },
            { name: "GCP", color: "rgb(255 149 0)" },
            { name: "Firebase", color: "rgb(255 149 0)" },
            { name: "Cloud Run", color: "rgb(255 149 0)" },
            { name: "Cloud SQL", color: "rgb(255 149 0)" },
        ]
    },
    {
        code: "pomelo",
        name: "Pomelo Fashion",
        title: "Software Engineering Director",
        date: "May 2021 - Aug 2023 (2 years 4 months)",
        tags: [
            { name: "Engineering Director" },
            { name: "Engineering Manager" },
            { name: "Direction Planning" },
            { name: "Feature Roadmap" },
            { name: "Team Building" },
            { name: "Full-stack" },
            { name: "Swift", color: "secondary" },
            { name: "Kotlin", color: "secondary" },
            { name: "Terraform", color: "secondary" },
            { name: "AWS", color: "secondary" },
            { name: "Search", color: "secondary" },
            { name: "CDP", color: "secondary" },
            { name: "ML", color: "secondary" },
            { name: "ESC", color: "rgb(255 149 0)" },
            { name: "Beanstalk", color: "rgb(255 149 0)" },
            { name: "EC2", color: "rgb(255 149 0)" },
            { name: "Lambda", color: "rgb(255 149 0)" },
            { name: "WAF", color: "rgb(255 149 0)" },
            { name: "S3", color: "rgb(255 149 0)" },
            { name: "RDS", color: "rgb(255 149 0)" },
            { name: "Cloud Watch", color: "rgb(255 149 0)" },
            { name: "Cloud Front", color: "rgb(255 149 0)" },
        ]
    },
    {
        code: "gable",
        name: "G-Able",
        title: "Development and Technology Manager",
        date: "Feb 2021 - Apr 2021 (3 months)",
        tags: [
            { name: "Product Owner" },
            { name: "Technology Manager" },
            { name: "User Interview" },
            { name: "Pitch Deck" },
            { name: "Rapid Software Prototyping" },
            { name: "OepnEdx", color: "secondary" },
            { name: "Power App", color: "secondary" },
            { name: "Power BI", color: "secondary" },
            { name: "React.js", color: "secondary" },
            { name: "Python", color: "secondary" },
            { name: "MongoDB", color: "secondary" },
        ]
    },
    {
        code: "insightera",
        name: "InsightEra",
        title: "Chief Technology Officer (CTO)",
        date: "Feb 2019 - Jan 2021 (2 years)",
        tags: [
            { name: "CTO" },
            { name: "Coaching" },
            { name: "Team Building" },
            { name: "Culture" },
            { name: "Company Direction Planning" },
            { name: "Resource Management" },
            { name: "Full-stack" },
            { name: "Research and Development" },
            { name: "Golang", color: "secondary" },
            { name: "Python", color: "secondary" },
            { name: "Node.js", color: "secondary" },
            { name: "Java", color: "secondary" },
            { name: "Kotlin", color: "secondary" },
            { name: "PHP", color: "secondary" },
            { name: "Vue.js", color: "secondary" },
            { name: "React.js", color: "secondary" },
            { name: "Elasticsearch", color: "secondary" },
            { name: "MongoDB", color: "secondary" },
            { name: "Redis", color: "secondary" },
            { name: "Swarm", color: "secondary" },
            { name: "Tensorflow", color: "secondary" },
            { name: "RabbitMQ", color: "secondary" },
            { name: "EFK", color: "secondary" },
            { name: "NLP", color: "secondary" },
            { name: "ML", color: "secondary" },
            { name: "DevOps", color: "secondary" },
        ]
    },
    {
        code: "metamedia",
        name: "Metamedia",
        title: "Senior Full-stack Developer",
        date: "Oct 2013 - Jan 2019 (5 years+)",
        tags: [
            { name: "Full-stack Dev" },
            { name: "Technical Consult" },
            { name: "Project Management" },
            { name: "Coaching" },
            { name: "Research and Development" },
            { name: "Android", color: "secondary" },
            { name: "Java", color: "secondary" },
            { name: "Kotlin", color: "secondary" },
            { name: "Node.js", color: "secondary" },
            { name: "Redis", color: "secondary" },
            { name: "Python", color: "secondary" },
            { name: "PHP", color: "secondary" },
            { name: "Hadoop", color: "secondary" },
            { name: "Elasticsearch", color: "secondary" },
            { name: "Memcache", color: "secondary" },
            { name: "Drupal", color: "secondary" },
            { name: "Symfony", color: "secondary" },
            { name: "Laravel", color: "secondary" },
            { name: "OpenGL", color: "secondary" },
            { name: "WebGL", color: "secondary" },
            { name: "Three.js", color: "secondary" },
            { name: "DevOps", color: "secondary" },
            { name: "PostgreSQL", color: "secondary" },
            { name: "PostGIS", color: "secondary" },
            { name: "MySQL", color: "secondary" },
        ]
    },
    // {
    //     code: "accenture",
    //     name: "Accenture",
    //     title: "Junior Consultant",
    //     date: "Jun 2013 - Aug 2013 (3 months)",
    //     tags: [
    //         { name: "Project Management" },
    //     ]
    // },
]

export default function Album() {
    const classes = useStyles();
    const [menuState, setMenuState] = React.useState({
        competency: false,
        skill: false,
        tool: false,
        db: false,
        other: false,
        gable: false,
        insightera: false,
        metamedia: false,
    });

    const handleClickMenu = (key) => {
        setMenuState({
            ...menuState,
            [key]: !menuState[key]
        })
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        Portfolio
                    </Typography>
                </Toolbar>
            </AppBar>
            <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container maxWidth="md">
                        <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
                            Punyapat Sessomboon (Perth)
                        </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" paragraph>
                            CTO, Head of Technology and Product, Software Engineering Director,<br/>Senior Full-stack, Data and ML/NLP
                        </Typography>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <a href="https://drive.google.com/file/d/1O1xL3qD4Y5K9CF-r-DuYX7V1DO7Fazai/view?usp=sharing" target="_blank" rel="noreferrer" className={classes.link}>
                                        <Button variant="contained" color="primary" startIcon={<DescriptionIcon />}>
                                            Resume
                                        </Button>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://www.linkedin.com/in/punyapat-s/" target="_blank" rel="noreferrer" className={classes.link}>
                                        <Button variant="outlined" color="primary" startIcon={<LinkedInIcon />}>
                                            Linkedin
                                        </Button>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://perth.medium.com" target="_blank" className={classes.link} rel="noreferrer" >
                                        <Button variant="outlined" color="primary" startIcon={<StarIcon />}>
                                            Medium
                                        </Button>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://github.com/perthcpe23" target="_blank" className={classes.link} rel="noreferrer" >
                                        <Button variant="outlined" color="primary" startIcon={<GitHubIcon />}>
                                            Github
                                        </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="lg">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {cards.map((card) => (
                            <Grid item key={card.title} xs={12} sm={6} md={4}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.cardMedia}
                                        image={card.imageUrl}
                                        title={card.title}
                                    />
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {card.title}
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {card.desc}
                                        </Typography>
                                        {card.detail && <Typography variant="body2" gutterBottom>
                                            {card.detail}
                                        </Typography>}
                                        <Typography variant="subtitle1" className={classes.tags}>
                                            {card.tags.join(", ")}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        {
                                            card.url &&
                                            (
                                                <a href={card.url} target="_blank" className={classes.link} rel="noreferrer">
                                                    <Button size="small" color="primary">
                                                        View
                                                    </Button>
                                                </a>
                                            )
                                        }
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Container maxWidth="lg" className={classes.skills}>
                    <Grid container spacing={2}>
                        {
                            logos.map(e => {
                                return <Grid item xs={2} md={1}>
                                    <img className={classes.logo} src={e.src} alt={e.alt} title={e.alt} />
                                </Grid>
                            })
                        }
                    </Grid>
                </Container>
                <Container maxWidth="lg" className={classes.skills}>
                    <Typography variant="h6" gutterBottom>
                        Skills and competencies
                    </Typography>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                    >
                        {/* <ListItem button onClick={() => handleClickMenu('competency')}>
                            <ListItemIcon>
                                <StarIcon />
                            </ListItemIcon>
                            <ListItemText primary="Core competencies" />
                            {menuState.competency ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={menuState.competency} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.nested}>
                                {coreCompetencies.map((e) => {
                                    return (
                                        <Chip key={e.name} label={e.name} className={classes.chip} color="primary" variant="outlined" />
                                    )
                                })}
                            </List>
                        </Collapse> */}
                        <ListItem button onClick={() => handleClickMenu('skill')}>
                            <ListItemIcon>
                                <LayersIcon />
                            </ListItemIcon>
                            <ListItemText primary="Mainly used languages" />
                            {menuState.skill ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={menuState.skill} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.nested}>
                                {programmings.map((e) => {
                                    return (
                                        <Chip key={e.name} label={e.name} className={classes.chip} variant="outlined" color="secondary" />
                                    )
                                })}
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => handleClickMenu('db')}>
                            <ListItemIcon>
                                <ViewAgendaIcon />
                            </ListItemIcon>
                            <ListItemText primary="Databases, search index, cache" />
                            {menuState.db ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={menuState.db} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.nested}>
                                {databases.map((e) => {
                                    return (
                                        <Chip key={e.name} label={e.name} className={classes.chip} variant="outlined" color="secondary" />
                                    )
                                })}
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => handleClickMenu('tool')}>
                            <ListItemIcon>
                                <BuildIcon />
                            </ListItemIcon>
                            <ListItemText primary="Tools" />
                            {menuState.tool ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={menuState.tool} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.nested}>
                                {tools.map((e) => {
                                    return (
                                        <Chip
                                            key={e.name}
                                            label={e.name}
                                            className={classes.chip}
                                            variant="outlined"
                                            style={{ color: e.color, borderColor: e.color }}
                                            color={e.color || "secondary"}
                                        />
                                    )
                                })}
                            </List>
                        </Collapse>
                        <ListItem button onClick={() => handleClickMenu('other')}>
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary="Other" />
                            {menuState.other ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={menuState.other} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.nested}>
                                {other.map((e) => {
                                    return (
                                        <Chip key={e.name} label={e.name} className={classes.chip} variant="outlined" color="secondary" />
                                    )
                                })}
                            </List>
                        </Collapse>
                    </List>
                </Container>
                <Container maxWidth="lg" className={classes.careers}>
                    <Typography variant="h6" gutterBottom>
                        Career background
                    </Typography>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                    >
                        {
                            careers.map((career) => {
                                return (
                                    <div>
                                        <ListItem button onClick={() => handleClickMenu(career.code)}>
                                            <ListItemIcon>
                                                <BusinessIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={career.name} />
                                            {menuState[career.code] ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={menuState[career.code]} timeout="auto" unmountOnExit>
                                            <Typography variant="subtitle1" gutterBottom className={classes.careerTitle}>
                                                <b>{career.title}</b> - {career.date}
                                            </Typography>
                                            <List component="div" disablePadding className={classes.nested}>
                                                {career.tags.map((e) => {
                                                    return (
                                                        <Chip
                                                            key={e.name}
                                                            label={e.name}
                                                            style={{ color: e.color, borderColor: e.color }}
                                                            className={classes.chip}
                                                            color={e.color || "primary"}
                                                            variant="outlined" />
                                                    )
                                                })}
                                            </List>
                                        </Collapse>
                                    </div>
                                )
                            })
                        }
                    </List>
                </Container>
            </main>
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    Contact
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    <Typography variant="body1" component="h2">
                        Punyapat Sessomboon (Perth)
                    </Typography>
                    <Typography gutterBottom variant="body1" component="h2">
                        ปุญญพัฒน์ เศรษฐ์สมบูรณ์ (เพิร์ท)
                    </Typography>
                    {/* <a href="tel:+6687-717-0741">(+66) 87-717-0741</a><br /> */}
                    <a href="mailto:perth.s28@gmail.com">perth.s28@gmail.com</a>
                </Typography>
            </footer>
        </React.Fragment>
    );
}